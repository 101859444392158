<template>
  <section id="container" class="gnb_pdt">
    <div class="title_wrap">
      <h1>공지사항 상세</h1>
    </div>
    <div class="contents_wrap">
      <ul class="filter_list">
        <li>
          <dd>
            <label>공지 구분</label>
            <ul>
              <li>
                {{ boardInfo.boardGbnNm }}
              </li>
            </ul>
          </dd>
          <dd>
            <label>상단공지</label>
            <ul>
              <li>
                {{ boardInfo.alwYn }}
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dd>
            <label>조회 구분</label>
            <ul>
              <li>
                {{ boardInfo.viewAllYn === 'Y'? '모든업체' : (boardInfo.viewAllYn === 'N' ? '선택업체' : '')}}
              </li>
            </ul>
          </dd>
          <dd>
            <label>공지기간</label>
            <ul class="w50">
              <li>
                {{ boardInfo.srtDt }} ~ {{ boardInfo.endDt }}
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dd class="w100">
            <label style="width: 9.5%;">공지 업체</label>
            <ul style="width: 85%;">
              <li v-if="boardInfo.viewAllYn ==='N'">
                <div class="txt_company w100">
                  <p v-for="item in boardInfo.boardCmpyParamList">{{ item.cmpyNm}}</p>
                </div>
              </li>
              <li v-else-if="boardInfo.viewAllYn ==='Y'">
                <div class="txt_company w100">
                  <p>모든업체</p>
                </div>
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dd>
            <label>작성자</label>
            <ul>
              <li>
                {{ boardInfo.frstRgstNm }}
              </li>
            </ul>
          </dd>
          <dd>
            <label>작성일</label>
            <ul>
              <li>
                {{ boardInfo.frstRgstDt }}
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dd>
            <label>제목</label>
            <ul class="w70">
              <li>
                {{ boardInfo.title }}
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dd class="w100">
            <label class="w10">내용</label>
            <ul class="w80">
              <li>
                <div v-html="boardInfo.contentsHtml"></div>
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dd class="w100">
            <label class="w10">첨부파일</label>
            <ul class="w80">
              <div class="icon_file">
                <li v-for="item in boardInfo.file" class="">
                  <a @click="EtnersEfsUtil.fileDownload(ApiConfig.efsDomain + '/efs/file/download/' + boardInfo.fileId + '/' + item.fileSeqNo)" href="#" class="icon_file">
                    <span class="k-icon k-i-download"></span>
                    {{ item.orgFileNm }}</a>
                </li>
              </div>
            </ul>
          </dd>
        </li>
      </ul>
    </div>
    <div class="button_area w30">
      <ul>
        <li class="w30">
          <button class="large_btn" @click="$router.push({name : 'BoardList'})">목록</button>
        </li>
        <li class="w30">
          <button v-if="loginUserData.auth !== Constant.efs.auth.OPERATION_MANAGER"
                  class="large_btn orange" @click="$router.push({name:'BoardEdit'})">수정</button>
        </li>
      </ul>
    </div>
    <kendo-window
        ref="searchCmpyForBoardWindowRef"
        :title="'공지 업체 선택'"
        :modal="true"
        :width="'800px'"
        @open="getCmpyCdList"
        style="display:none; "
    >
      <div class="pop-container">
        <div class="pop-conts">
          <ul class="filter_list srm_check">
            <li>
              <dd class="w100"><label class="pop_left_title w20">회사 </label>
                <ul class="pop_right_area w80">
                  <li>
                    <common-radio-group
                        ref="cmpyGbnRef"
                        :default-value="''"
                        v-model="searchCmpyGbn"
                        @click="e=>{
                          searchCmpyGbn = e.value
                          getCmpyCdList(e)
                        }"
                        :data-items="[{cdId : '', cdNm : '전체'},...$store.state.codeRequestStore.search.combobox.cmpyGbn.data]"
                        :data-value-field="'cdId'"
                        :data-text-field="'cdNm'"
                    ></common-radio-group>
                  </li>
                </ul>
              </dd>
            </li>
          </ul>
        </div>
        <div class="pop_notice_company">
          <kendo-listbox
              ref="leftListBoxRef"
              @remove="removeLeftListBox"
              :data-source="cmpyListDataSource"
              data-text-field="cmpyNm"
              id="optional"
              :draggable="true"
              :connect-with="'selected'"
              :drop-sources="['selected']"
              :toolbar-tools="['transferTo', 'transferFrom', 'transferAllTo', 'transferAllFrom']">
            <!-- <option v-for="cmpy in cmpyList" v-if="checkCmpy(cmpy)">{{cmpy.cmpyNm}}</option> -->
          </kendo-listbox>
          <kendo-listbox
              @remove="removeRightListBox"
              :data-source="selectedCmpyListDataSource"
              data-text-field="cmpyNm"
              id="selected"
              :draggable="true"
              :connect-with="'optional'"
              :drop-sources="['optional']">
          </kendo-listbox>
        </div>
      </div>
    </kendo-window>
  </section>
</template>

<script>

import ApiUtil from '/src/api/api.util'
import ApiConfig from '/src/api/api.config'
import { mapGetters } from 'vuex'
import EtnersCommonUtil from '@ebmp-fe-common-module/module/src/common/etners.common.util'
import EtnersEfsUtil from '@/common/etners.efs.util'
import Constant from '@/common/constant'

export default {
  name      : 'BoardDetail',
  watch : {
    logiDepth2List : function(newValue, oldValue){
      EtnersCommonUtil.setRowNumber(newValue)
    }
  },
  computed : {
    ...mapGetters(['loginUserData'])
  },
  created () {
    const vm = this
    this.getLogiManageDetail()
  },
  methods   : {
    getCmpyCdList: function (e) {
      const vm = this
      let param = {
        loginUserToken: sessionStorage.loginUserToken,
        skip: 1,
        take: 10,
        page: 1,
        pageSize: 10,
        searchCmpyGbn: vm.searchCmpyGbn
      }
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/drop-down/company`, param)
      .then((response) => {
        if (
            response.data === undefined ||
            response.data.resultStatus === undefined
        ) {
          kendo.alert("데이터가 정상적으로 조회되지 않았습니다.");
          return false;
        }
        if (response.data.resultStatus.messageCode !== "2000") {
          kendo.alert(response.data.resultStatus.messageText);
          return false;
        }

        vm.selectedCmpyListDataSource.forEach((item,i)=>{
          for(let i in response.data.resultData){
            if(response.data.resultData[i].cmpyCd === item.cmpyCd){
              response.data.resultData.splice(i, 1)
              return
            }
          }
        })
        vm.cmpyListDataSource = response.data.resultData
      })
      .catch((error) => {
        console.log(error);
      });
    },
    /**
     * 왼쪽 리스트박스에서 오른쪽으로 넘길 떄 실행되는 method
     */
    removeLeftListBox: function(evt){
      const vm = this
      const cmpys = evt.dataItems

      cmpys.forEach((cmpy) => {
        let cmpyData = {
          cmpyCd: cmpy.cmpyCd,
          cmpyNm: cmpy.cmpyNm,
        }
        vm.selectedCmpyListDataSource.push(cmpyData)
        for(let i in vm.cmpyListDataSource){
          if(vm.cmpyListDataSource[i].cmpyCd === cmpy.cmpyCd){
            vm.cmpyListDataSource.splice(i,1)
            return
          }
        }
      })
    },

    /**
     * 오른쪽 리스트박스에서 왼쪽으로 넘길 때 실행되는 method
     */
    removeRightListBox: function(evt){
      const vm = this
      const cmpys = evt.dataItems

      cmpys.forEach((cmpy) => {
        const cmpyCd = cmpy.cmpyCd
        const cmpyData = {
          cmpyCd: cmpyCd,
          cmpyNm: cmpy.cmpyNm,
        }

        vm.uiData.cmpyList[cmpyCd] = cmpyData
        delete vm.uiData.selectedCmpyList[cmpyCd]
      })

      // vm.uiData.cmpyListDataSourceUpdateCounter++
    },
    getLogiManageDetail : function() {
      const vm = this
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/board/${vm.$route.params.boardId}`)
      .then(function (response) {
            if (response.data.resultStatus.messageCode === '2000') {
              vm.boardInfo = response.data.resultData
              const url = window.URL.createObjectURL(new Blob([response.data]))
            } else {
              kendo.alert(response.data.resultStatus.messageText)
            }
          },
      )
    },
    onClickboardInfoSave () {
      const vm = this
      if (vm.validator()) {
        kendo.confirm('저장 하시겠습니까?').done(
            function () {
              vm.logiManageSave()
            })
      }
    },
    logiManageSave() {

      const vm = this

      vm.logiDepth2List.forEach(item=>{
        item.useYn = item.VModelUseYn ? 'Y' : 'N'
      })
      vm.boardInfo.logiDepth2List = vm.logiDepth2List

      if(vm.isNew){
        ApiUtil.post(`${ApiConfig.efsDomain}/efs/module/logi`, vm.boardInfo).then(response => {
          if (!response.data?.resultStatus) {
            kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
            return false
          }
          if (response.data.resultStatus.messageCode !== '2000') {
            kendo.alert(response.data.resultStatus.messageText)
            return false
          }
          vm.$router.push({
            name: 'BoardDetail',
            params: {
              cmpyCd : response.data.resultData.cmpyCd,
              logiDepth1 : response.data.resultData.logiDepth1
            }
          })
        })
      }else {
        ApiUtil.put(`${ApiConfig.efsDomain}/efs/module/logi/${vm.$route.params.cmpyCd}/${vm.$route.params.logiDepth1}`, vm.boardInfo).then(response => {
          if (!response.data?.resultStatus) {
            kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
            return false
          }
          if (response.data.resultStatus.messageCode !== '2000') {
            kendo.alert(response.data.resultStatus.messageText)
            return false
          }
          vm.$router.push({
            name: 'BoardDetail',
            params: {
              cmpyCd : vm.$route.params.cmpyCd,
              logiDepth1 : vm.$route.params.logiDepth1
            }
          })
        })
      }
    },
    validator(){
      const vm = this
      if(!vm.boardInfo.cmpyCd){
        kendo.alert("회사를 선택해주세요.").bind('close', function() {
          vm.$refs.cmpyCdRef.widget().toggle()
        })
        return
      }
      if(!vm.boardInfo.logiDepth1){
        kendo.alert("적용그룹코드를 선택해주세요.").bind('close', function() {
          vm.$refs.logiDepth1Ref.kendoWidget().toggle()
        })
        return
      }

      return true
    },
  },
  data      : function () {
    const headerAttributes = { style: 'font-weight: bold; text-align: center;' }
    const centerAttributes = { style: 'text-align: center;' }
    return {
      ApiConfig,
      Constant,
      EtnersEfsUtil,
      isNew : true,
      baseData : {},
      uiData : {},
      searchCmpyGbn : '',
      cmpyListDataSource : [],
      selectedCmpyListDataSource : [],
      editor: {
        settings: {
          imageBrowser: {
            path: "",
            userId: "",
            entityKeyEnumsValue: "srm_notice"
          },
          editorTools: [
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "justifyLeft",
            "justifyCenter",
            "justifyRight",
            "justifyFull",
            "insertUnorderedList",
            "insertOrderedList",
            "indent",
            "outdent",
            "insertImage",
            "tableWizard",
            "createTable",
            "addRowAbove",
            "addRowBelow",
            "addColumnLeft",
            "addColumnRight",
            "deleteRow",
            "deleteColumn",
          ]
        }
      },
      boardInfo  : {
        VModelDt : {
          start : new Date(),
          end : null,
        },
      },
      rowItem : {
        data : {},
        index : null,
      },
      logiDepth2List : [],
      logiDepth2GridColumns : [
        {
          field: 'rowNumber',
          title: 'No',
          width: '3%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'logiDepth2',
          title: '코드번호',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'logiDepth2DisNm',
          title: '항목명',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'seqNo',
          title: '항목순서',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'useYn',
          title: '사용여부',
          width: '5%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
      ],
    }
  },
}
</script>

<style scoped>
</style>
